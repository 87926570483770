import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "components/common/Layout";
import { Logout, Hand, Globe } from "components/icons";
import { Button, Container, Modal } from "components/common";
import { Link } from "react-router-dom";
import { LocaleModal } from "components/Modal/LocaleModal";
import { useLng, useUser } from "hooks/dev";

export const Settings = () => {
  const { t } = useTranslation();
  const { language } = useLng();
  const { userInfo } = useUser();
  const { isActive } = userInfo || {};
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(null);

  const onClose = useCallback(() => {
    setIsOpenRemoveModal(null);
  }, [setIsOpenRemoveModal]);

  return (
    <Layout>
      <Container>
        <div className="pt-[20px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("settings", { defaultValue: "Settings" })}
          </div>
          <div className="flex flex-col border border-main w-full p-[10px] md:p-[20px] mb-4">
            <div className="text-xl text-main mb-4 font-bold">
              {t("account-info", { defaultValue: "Account Info" })}
            </div>
            <div>
              <b>{t("email", { defaultValue: "Email" })}:</b> {userInfo.email}
            </div>
          </div>
          <div className="flex flex-col border border-main w-full p-[10px] md:p-[20px] mb-10">
            <div className="pt-[10px] w-full h-full flex flex-col">
              <div className="text-xl text-main mb-4 font-bold">
                {t("subscription", { defaultValue: "Subscription" })}
              </div>
              <div>
                <b>{t("status", { defaultValue: "Status" })}:</b>{" "}
                {isActive
                  ? t("Activated", { defaultValue: "Activated" })
                  : t("Free", { defaultValue: "Free" })}
              </div>
            </div>
          </div>
          <div className="lg:hidden mt-auto flex flex-col gap-4 pb-6">
            <LocaleModal>
              <div className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer">
                <Globe className="w-4 h-4" /> {language}
              </div>
            </LocaleModal>
            <a
              href="mailto:taislygroup@gmail.com"
              className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer"
            >
              <Hand className="w-4 h-4" />
              <span>{t("help", { defaultValue: "Help" })}</span>
            </a>
            <a
              href="https://taisly.com/privacy-policy"
              className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer"
            >
              <span>
                {t("privacy-policy", { defaultValue: "Privacy Policy" })}
              </span>
            </a>
            <Link
              to="/logout"
              className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer"
            >
              <Logout className="w-4 h-4" />
              <span>{t("logout", { defaultValue: "Logout" })}</span>
            </Link>
          </div>
        </div>
      </Container>
      <Modal
        isOpen={Boolean(isOpenRemoveModal)}
        onClose={onClose}
        title={`Are you sure you want remove ${isOpenRemoveModal?.user?.platform} ${isOpenRemoveModal?.user?.username} connection?`}
      >
        <div className="grid grid-cols-2 gap-10 mt-10">
          <Button
            onClick={() => {
              isOpenRemoveModal?.callback();
              onClose();
            }}
          >
            {t("yes", { defaultValue: "Yes" })}
          </Button>
          <Button onClick={onClose}>{t("no", { defaultValue: "No" })}</Button>
        </div>
      </Modal>
    </Layout>
  );
};
