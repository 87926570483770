export const isDev = process.env.REACT_APP_IS_DEV === "true";

export const FE_HOST = isDev
  ? "https://www.localhost:8062"
  : "https://app.taisly.com";

export const PAYMENT_LINK = [
  {
    price: 5,
    month: 1,
    link: isDev
      ? "https://buy.stripe.com/test_4gweXH7sQ6rYcDKfYY"
      : "https://buy.stripe.com/00gg1o45hfjk3vOfYY",
    payment_link: isDev
      ? "plink_1OL2nhGJeOREexyvsmyrCAxR"
      : "plink_1ONU24GJeOREexyv9HwGc9UO",
  },
  {
    price: 49.99,
    month: 12,
    link: isDev
      ? "https://buy.stripe.com/test_4gweXH7sQ6rYcDKfYY"
      : "https://buy.stripe.com/8wM7uSatF8UW2rKfYZ",
    payment_link: isDev
      ? "plink_1OL2nhGJeOREexyvsmyrCAxR"
      : "plink_1OzgtMGJeOREexyvRkGX0lyT",
  },
];

export const config = {
  payments: {
    M: {
      price: 5,
      month: 1,
      link: isDev
        ? "https://buy.stripe.com/test_4gweXH7sQ6rYcDKfYY"
        : "https://buy.stripe.com/00gg1o45hfjk3vOfYY",
      plink: isDev
        ? "plink_1OL2nhGJeOREexyvsmyrCAxR"
        : "plink_1ONU24GJeOREexyv9HwGc9UO",
    },
    Y: {
      price: 49.99,
      month: 12,
      link: isDev
        ? "https://buy.stripe.com/test_4gweXH7sQ6rYcDKfYY"
        : "https://buy.stripe.com/8wM7uSatF8UW2rKfYZ",
      plink: isDev
        ? "plink_1OL2nhGJeOREexyvsmyrCAxR"
        : "plink_1OzgtMGJeOREexyvRkGX0lyT",
    },
    F: {
      price: 99,
      month: 200,
      link: "https://buy.stripe.com/aEU9D059l9Z0easbIK",
      plink: "plink_1Q0OLvGJeOREexyvJ97bbSy9",
    },
  },
};

export const LIMIT = 100;

export const META_AUTH_LINK = `https://www.facebook.com/v18.0/dialog/oauth?client_id=6839380202789530&redirect_uri=${
  isDev ? "https://localhost:8062/post" : "https://app.taisly.com/post"
}&scope=pages_show_list,pages_manage_posts,instagram_content_publish,business_management,pages_read_engagement,instagram_basic&auth_type=rerequest`;

export const PLATFORMS_LIST = [
  "Instagram",
  "TikTok",
  "YouTube",
  "X",
  "Facebook",
];

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const localesMap = {
  en: {
    name: "English",
  },
  de: {
    name: "Deutsch",
  },
  th: {
    name: "ไทย",
  },
  // es: {
  //   name: "Español",
  // },
  // fr: {
  //   name: "Français",
  // },
  // "pt-PT": {
  //   name: "Português (Portugal)",
  // },
};
