import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button } from "../common";
import { config } from "constants";
import { useUser } from "hooks/dev";
import { Logo } from "components/icons/Logo";

export const Paywall = () => {
  const [selected, setSelected] = useState("Y");
  const { isLoading: isLoadingUser, isAuth, userInfo } = useUser();

  const { isActive } = userInfo || {};
  const { t } = useTranslation();

  const onSubmit = () => {
    const link = config?.payments?.[selected]?.link;
    if (link)
      window.location.href = `${link}?client_reference_id=${userInfo?.userId}`;
  };

  if (isLoadingUser || !isAuth || isActive) return false;

  return (
    <div className="fixed inset-0 z-50 w-full min-h-[calc(100dvh)] bg-third">
      <div className="relative h-full lg:grid lg:grid-cols-2 lg:gap-2">
        <div className="flex flex-col h-full">
          <div className="p-6 flex-shrink-0">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">
                {t("subscribe", "Subscribe")}
              </h2>
            </div>
            <p className="text-gray-600">
              {t(
                "paywall-desc",
                "Best way to auto post your videos to social media platforms!"
              )}
            </p>
          </div>

          <div className="flex-grow overflow-y-auto px-6">
            <div className="space-y-3 mb-6">
              {[
                {
                  key: "M",
                  duration: t("monthly", { defaultValue: "Monthly" }),
                  price: "$5",
                  perPeriod: "/ Month",
                },
                {
                  key: "Y",
                  duration: t("annual", "Annual"),
                  price: "$4",
                  perPeriod: "/ Month, billed yearly at $49,99",
                  popular: true,
                },
              ].map((plan) => (
                <div
                  key={plan.duration}
                  onClick={() => setSelected(plan.key)}
                  className={`p-3 rounded-lg border cursor-pointer ${
                    plan.key === selected
                      ? "border-main bg-green-50"
                      : "border-gray-200"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-semibold text-xl flex items-center">
                      {plan.duration}{" "}
                      {/* {plan.key === "Y" ? (
                        <span className="text-sm ml-1">Save 20%</span>
                      ) : null} */}
                    </span>
                    {plan.popular && (
                      <span className="text-xs font-semibold text-main bg-acent px-2 py-1 rounded">
                        {t("popular", "Popular")}
                      </span>
                    )}
                  </div>
                  <div className="text-main font-bold">
                    {plan.price}{" "}
                    {plan.perPeriod ? (
                      <span className="text-gray-500 font-normal text-sm">
                        {plan.perPeriod}
                      </span>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="p-6 flex-shrink-0">
            <Button className="w-full" onClick={onSubmit}>
              {t("submit", "Submit")}
            </Button>
          </div>
        </div>
        <div className="hidden lg:flex border-0 border-solid border-l-[1px] bg-main justify-center items-center">
          <Logo className="w-[200px] text-acent" />
        </div>
      </div>
    </div>
  );
};
